<template>
    <div class="basic-info">
      <div class="form-entry-container">
      <div class="form-entry-label">Change Project to:</div>
      <div class="form-entry-input">
        <kendo-dropdownlist
          :ref="'projects'"
          :data-source="projectList"
          :data-value-field="'ProjectIdentity'"
          :data-text-field="'ProjectName'"
          :change="change">
        </kendo-dropdownlist>
        <kendo-button :ref="'apply'" @click="applyClick()" v-if="apply">Apply</kendo-button>
          <div style="width: 500px;">
            <a  href="#">{{ generalConfigurationTitle }}</a>
            <kendo-button class="k-button" :ref="'importConfiguration'" v-if="importConfigurationVisible" @click="openImportConfigurationWindow()">Import configuration template</kendo-button>
            <div class="note-text">
            <div v-if="importedConfigurationTemplate">PRESTO will show only the general info parameters. However, all other parameters in this file will be saved once you create the new project.</div>
          </div>
          </div>
        </div>
        <div v-if="showGeneralInfo">
          <h5 v-if="showGeneralInfoTitle">{{ generalInfoTitle }}</h5>
          <FormEntryContainer title="Project Number" type="text" size="10" :value="projectNumber" :readonly="readOnly"></FormEntryContainer>
          <FormEntryContainer title="Project Name" type="text" size="100" :value="projectName" :readonly="readOnly"></FormEntryContainer>
          <FormEntryContainer title="Project Description" type="textarea" cols="200" rows="10" :value="projectDescription" :readonly="readOnly"></FormEntryContainer>
          <FormEntryContainer title="Project Type" type="dropdown" :datasource="this.projectTypeDataSource" :valuefield="'ProjectTypeIdentity'" :textfield="'ProjectTypeName'" :readonly="readOnly" value="1"></FormEntryContainer>
          <FormEntryContainer title="Project Status" type="dropdown" :datasource="this.projectStatusDataSource" :valuefield="'ProjectStatusIdentity'" :textfield="'ProjectStatusName'" :readonly="readOnly" value="1"></FormEntryContainer>
          <FormEntryContainer title="Project Lead (PI)" type="text" size="50" :value="projectLead" :readonly="readOnly"></FormEntryContainer>
          <FormEntryContainer title="Project Client" type="text" size="50" :value="projectClient" :readonly="readOnly"></FormEntryContainer>
          <div class="form-entry-container">
            <div class="form-entry-label">Review Type (Previously Data Entry Type)</div>
            <div class="form-entry-input">
              <input type="radio" name="de-options" id="single" @click="changeDataEntry" :disabled='readOnly' :checked="!this.$store.state.isDoubleDataEntry">
              <label for="single">Single review</label>
              <input type="radio" name="de-options" id="single" @click="changeDataEntry" :disabled='readOnly' :checked="this.$store.state.isDoubleDataEntry">
              <label for="single">Double review</label>
            </div>
          </div>
          <div class="form-entry-container">
            <div class="form-entry-label">Allow review validation</div>
            <div class="form-entry-input">
              <input type="radio" name="allowvalidation" id="allowvalidation" :disabled='readOnly' :checked="true">
              <label for="single">Yes</label>
              <input type="radio" name="allowvalidation" id="allowvalidation" :disabled='readOnly'>
              <label for="single">No</label>
            </div>
          </div>
          <div  :class="[readonly? 'text-disabled': 'form-entry-container']">
            <div class="form-entry-label">Data Extraction Type</div>
            <div class="form-entry-input">
              <div><input type="radio" name="de-options2" id="extractionType" :disabled='readOnly' :checked="true">No extraction</div>
              <div><input type="radio" name="de-options2" id="extractionType" :disabled='readOnly'>Single extraction</div>
              <div><input type="radio" name="de-options2" id="extractionType" :disabled='readOnly'>Double extraction</div>
            </div>
          </div>
          <FormEntryContainer title="Allow forms to finish later  (To be removed???)" name="yesNo3" type="radioYesNo" :readonly="readOnly"></FormEntryContainer>
          <FormEntryContainer title="Criteria for suspected duplicate (To be removed)" type="text" :readonly="readOnly"></FormEntryContainer>
          <kendo-button v-if="saveAndApply">Save and Apply</kendo-button>
        </div>
      </div>
      <kendo-window :ref="'configurationFileWindow'" :title="configurationFileSelection" :width="'500px'" :modal="true" :visible="importConfigurationWindowVisible" :top="'50px'" :left="'200px'">
        <div class="note-text">
          <div>PRESTO will show only the general info parameters. However, all other parameters in this file will be saved once you create the new project.</div>
          <!-- <div>However, all other parameters in this file will be saved once you create the new project.</div> -->
          <!-- <div>Once you select this import file, PRESTO will show only the general info parameters.</div>
          <div>However, all other parameters in this file will be saved for the new project.</div> -->
        </div>
        <FormEntryContainer title="Select configuration template file" type="file"></FormEntryContainer>
        <kendo-button class="k-button" :ref="'importConfigurationFile'" @click="closeImportConfigurationWindow()">Import configuration template</kendo-button>
        <kendo-button class="k-button">Cancel</kendo-button>
      </kendo-window>
    </div>
</template>

<script>
import FormEntryContainer from '@/containers/FormEntryContainer'
import ProjectTypeDataSource from '@/assets/data/ProjectType.json'
import ProjectStatusDataSource from '@/assets/data/ProjectStatus.json'

export default {
  name: 'home',
  components: {
    FormEntryContainer
  },
  created: function () {
    this.$refs.apply.disabled = 'disabled'
    this.$store.state.showActions = false
  },
  mounted: function () {
    this.showGeneralInfo = true
    this.importConfigurationVisible = true
    this.generalConfigurationTitle = 'Download configuration template for new project'
    if (!this.$store.state.isLogged) {
      this.$router.push('/')
    }
  },
  methods: {
    changeDataEntry: function () {
      this.$store.state.isDoubleDataEntry = !this.$store.state.isDoubleDataEntry
    },
    applyClick: function () {
      this.$router.push('GeneralInfo')
    },
    change: function (e) {
      if (e.sender.selectedIndex === 0) {
        this.showGeneralInfo = true
        this.showGeneralInfoTitle = true
        this.generalInfoTitle = 'General Info for New project'
        this.generalConfigurationTitle = 'Download configuration template for new project'
        this.readOnly = false
        this.saveAndApply = true
        this.$refs.apply.disabled = ''
        this.projectNumber = ''
        this.projectName = ''
        this.projectDescription = ''
        this.projectLead = ''
        this.projectClient = ''
        this.applyTitle = 'Apply'
        this.apply = false
        this.applyDisabled = false
        this.importConfigurationVisible = true
        this.importedConfigurationTemplate = false
      } else if (e.sender.selectedIndex > 0) {
        this.showGeneralInfo = true
        this.showGeneralInfoTitle = true
        this.generalInfoTitle = 'General Info for ' + this.projectList[e.sender.selectedIndex].ProjectName
        this.generalConfigurationTitle = 'Download configuration template for ' + this.projectList[e.sender.selectedIndex].ProjectName
        this.readOnly = true
        this.applyTitle = 'Apply'
        this.apply = true
        this.saveAndApply = false
        this.applyDisabled = false
        this.importConfigurationVisible = false
        this.importedConfigurationTemplate = false
        this.projectNumber = this.projectList[e.sender.selectedIndex].ProjectNumber
        this.projectName = this.projectList[e.sender.selectedIndex].ProjectName
        this.projectDescription = this.projectList[e.sender.selectedIndex].ProjectDescription
        this.projectLead = this.projectList[e.sender.selectedIndex].ProjectLead
        this.projectClient = this.projectList[e.sender.selectedIndex].ProjectClient
      } else {
        this.showGeneralInfo = false
        this.showGeneralInfoTitle = false
        this.generalInfoTitle = ''
        this.generalConfigurationTitle = 'Download configuration template for new project'
        this.readOnly = true
        this.apply = false
        this.saveAndApply = false
        this.applyTitle = 'Apply'
        this.applyDisabled = true
        this.importConfigurationVisible = false
        this.importedConfigurationTemplate = false
      }
      this.$store.state.selectedProject = this.$refs.projects.kendoWidget().value() === '' ? '' : this.$refs.projects.kendoWidget().text()
    },
    createNewProject: function () {
      this.$router.push('/Secure/ViewAll')
    },
    openImportConfigurationWindow: function () {
      // this.importConfigurationWindowVisible = true
      var windowWidget = this.$refs.configurationFileWindow.kendoWidget()
      windowWidget.open().center()
    },
    closeImportConfigurationWindow: function () {
      this.projectNumber = 'IMP1'
      this.projectName = 'Import project Name'
      this.projectDescription = 'Import project Description'
      this.projectLead = 'Import project Lead'
      this.projectClient = 'Import project Client'
      this.readOnly = false
      this.importedConfigurationTemplate = true
      var windowWidget = this.$refs.configurationFileWindow.kendoWidget()
      windowWidget.close()
    }
  },
  data () {
    return {
      readOnly: false,
      showGeneralInfo: false,
      showGeneralInfoTitle: false,
      generalConfigurationTitle: '',
      configurationFileSelection: 'Import configuration template',
      currentProject: '',
      applyTitle: 'Apply',
      apply: false,
      saveAndApply: false,
      applyDisabled: true,
      importConfigurationVisible: false,
      importConfigurationWindowVisible: false,
      importedConfigurationTemplate: false,
      projectTypeDataSource: ProjectTypeDataSource,
      projectStatusDataSource: ProjectStatusDataSource,
      projectNumber: '',
      projectDescription: '',
      projectLead: '',
      projectList: [
        { ProjectIdentity: '0', ProjectName: 'New project' },
        { ProjectIdentity: '1', ProjectName: 'Project 1', ProjectNumber: 'PROJ1', ProjectDescription: 'Project Description for Project 1', ProjectType: 1, ProjectStatus: 1, ProjectLead: 'Lead 1', ProjectClient: 'Client 1' },
        { ProjectIdentity: '2', ProjectName: 'Project 2', ProjectNumber: 'PROJ2', ProjectDescription: 'Project Description for Project 2', ProjectType: 1, ProjectStatus: 2, ProjectLead: 'Lead 2', ProjectClient: 'Client 2' },
        { ProjectIdentity: '3', ProjectName: 'Project 3', ProjectNumber: 'PROJ3', ProjectDescription: 'Project Description for Project 3', ProjectType: 2, ProjectStatus: 1, ProjectLead: 'Lead 3', ProjectClient: 'Client 3' },
        { ProjectIdentity: '4', ProjectName: 'Project 4', ProjectNumber: 'PROJ4', ProjectDescription: 'Project Description for Project 4', ProjectType: 1, ProjectStatus: 4, ProjectLead: 'Lead 4', ProjectClient: 'Client 4' }
      ]
    }
  }
}
</script>

<style>
  .text-disabled {
    background-color: lightgrey;
    color: grey;
    cursor: not-allowed;
  }

  h5 {
    font-size: 16px !important;
    width: 300px !important;
  }

  .note-text {
    color: red;
    font-weight: bold;
  }
</style>
